
import { Options, Vue } from 'vue-class-component';
import { categoryService } from '@/services/category.service';

@Options({
  emits: ['created'],
})
export default class EzAddcategory extends Vue {
  name = '';

  showForm = false;

  isSaving = false;

  nameError = '';

  onAddClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.categoryNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  clearData(): void {
    this.name = '';
  }

  async handleSubmit(): Promise<void> {
    this.validateName();

    if (this.nameError) {
      return;
    }
    this.isSaving = true;
    const newcategory = { name: this.name };
    try {
      const createdcategory = await categoryService.create(newcategory);
      this.$emit('created', createdcategory);
      this.clearData();
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
