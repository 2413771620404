<template>
  <div class="card is-clickable" :class="{ 'has-background-primary': isSelected }">
    <div class="card-content">
      <div class="content">
        <h4>{{ category.name }}</h4>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Category } from '@/api-domain/category';

@Options({
  components: {
  },
})
export default class EzPack extends Vue {
  @Prop({ required: true }) category!: Category;

  @Prop({ required: false }) isSelected = false;
}
</script>
