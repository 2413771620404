<template>
  <div class="categories container pb-6">
    <draggable v-model="categories" @end="onDragEnd" itemKey="category.id">
      <template #item="{ element }">
        <div class="block">
          <EzCategory
          :category="element"
          :isSelected="selectedCategory === element"
          @click="onCategoryClick(element)"
        />
        </div>
      </template>
    </draggable>
  </div>
  <div class="buttons has-background-grey-light p-2">
    <div class="container is-flex">
      <EzAddCategory @created="onCategoryCreated"></EzAddCategory>
      <EzEditCategory
        v-if="!!selectedCategory"
        @updated="onCategoryUpdated"
        :category="selectedCategory">
      </EzEditCategory>
      <EzDeleteCategory
        v-if="!!selectedCategory"
        @deleted="onCategoryDeleted"
        :category="selectedCategory">
      </EzDeleteCategory>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import draggable from 'vuedraggable';
import EzCategory from '@/components/categories/EzCategory.vue';
import EzAddCategory from '@/components/categories/EzAddCategory.vue';
import EzEditCategory from '@/components/categories/EzEditCategory.vue';
import EzDeleteCategory from '@/components/categories/EzDeleteCategory.vue';
import { Category } from '@/api-domain/category';
import { categoryService } from '@/services/category.service';

@Options({
  components: {
    EzAddCategory,
    EzEditCategory,
    EzDeleteCategory,
    EzCategory,
    draggable,
  },
})
export default class Categories extends Vue {
  categories: Category[] = [];

  selectedCategory: Category | null = null;

  fetchError = false;

  isFetched = false;

  assignOrderToItems(): void {
    this.categories = this.categories.map((item, index) => ({ ...item, order: index }));
  }

  onDragEnd(): void {
    this.assignOrderToItems();
    categoryService.updateMultiple(this.categories);
  }

  async fetchCategories(): Promise<void> {
    try {
      this.categories = await categoryService.getAll();
      this.isFetched = true;
    } catch {
      this.fetchError = true;
      // TODO: how to handle? polling in every 10 seconds?
      // try again button?
    }
  }

  async created(): Promise<void> {
    await this.fetchCategories();
  }

  async onCategoryCreated(): Promise<void> {
    await this.fetchCategories();
  }

  async onCategoryUpdated(): Promise<void> {
    this.selectedCategory = null;
    // Should be fetched, because the ordering logic is on the server side
    await this.fetchCategories();
  }

  async onCategoryDeleted(): Promise<void> {
    this.selectedCategory = null;
    await this.fetchCategories();
  }

  onCategoryClick(category: Category): void {
    if (this.selectedCategory === category) {
      this.selectedCategory = null;
    } else {
      this.selectedCategory = category;
    }
  }
}
</script>
<style scoped lang="scss">
.buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
