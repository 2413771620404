<template>
  <div class="category-add">
    <div class="field has-addons">
      <button
        class="button mr-2 is-primary is-light"
        v-on:click="onAddClick()"
      >
        Add new category
      </button>
    </div>
  </div>
  <div class="modal" :class="{ 'is-active': showForm }">
    <div class="modal-background"></div>
      <div class="modal-content">
        <div class="box">
          <form @submit.prevent="handleSubmit">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  ref="categoryNameRef"
                  placeholder="E.g. 'Grammar' or 'Vocabulary - Level 1'"
                  v-model="name"
                  :disabled="isSaving"
                />
                <p v-if="nameError" class="help is-danger">{{ nameError }}</p>
              </div>
            </div>
            <div class="field is-grouped">
              <div class="control">
                <button
                  type="submit"
                  class="button is-primary"
                  v-bind:class="isSaving ? 'is-loading' : ''"
                >
                  Add
                </button>
              </div>
              <div class="control">
                <button
                  type="button"
                  class="button"
                  v-if="!isSaving"
                  v-on:click="onCancelClick()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { categoryService } from '@/services/category.service';

@Options({
  emits: ['created'],
})
export default class EzAddcategory extends Vue {
  name = '';

  showForm = false;

  isSaving = false;

  nameError = '';

  onAddClick(): void {
    this.showForm = true;
    setTimeout(() => {
      (this.$refs.categoryNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  clearData(): void {
    this.name = '';
  }

  async handleSubmit(): Promise<void> {
    this.validateName();

    if (this.nameError) {
      return;
    }
    this.isSaving = true;
    const newcategory = { name: this.name };
    try {
      const createdcategory = await categoryService.create(newcategory);
      this.$emit('created', createdcategory);
      this.clearData();
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
</script>
