
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { Category } from '@/api-domain/category';

@Options({
  components: {
  },
})
export default class EzPack extends Vue {
  @Prop({ required: true }) category!: Category;

  @Prop({ required: false }) isSelected = false;
}
