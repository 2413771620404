
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { categoryService } from '@/services/category.service';
import { Category } from '@/api-domain/category';

@Options({
  emits: ['updated'],
})
export default class EzEditCategory extends Vue {
  @Prop({ isRequired: true }) category!: Category;

  name = '';

  showForm = false;

  isSaving = false;

  nameError = '';

  onEditClick(): void {
    this.name = this.category.name;

    this.showForm = true;
    setTimeout(() => {
      (this.$refs.categoryNameRef as HTMLInputElement).focus();
    }, 0);
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  validateName(): void {
    this.nameError = !this.name.trim() ? 'Name should be filled' : '';
  }

  async handleSubmit(): Promise<void> {
    if (!this.category.id) {
      throw new Error('Missing id property');
    }

    this.validateName();

    if (this.nameError) {
      return;
    }
    this.isSaving = true;
    const newCategory = { name: this.name };
    try {
      const createdCategory = await categoryService.update(this.category.id, newCategory);
      this.$emit('updated', createdCategory);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
