
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { categoryService } from '@/services/category.service';
import { Category } from '@/api-domain/category';

@Options({
  emits: ['deleted'],
})
export default class EzDeleteCategory extends Vue {
  @Prop({ isRequired: true }) category!: Category;

  showForm = false;

  isSaving = false;

  onDeleteClick(): void {
    this.showForm = true;
  }

  onCancelClick(): void {
    this.showForm = false;
  }

  async onSubmit(): Promise<void> {
    if (!this.category.id) {
      throw new Error('Missing id property');
    }

    this.isSaving = true;
    try {
      await categoryService.delete(this.category.id);
      this.$emit('deleted', this.category.id);
      this.isSaving = false;
      this.showForm = false;
    } catch {
      this.isSaving = false;
    }
  }
}
